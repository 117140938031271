<template>
  <footer>
    <figure>
      <router-link to="/" @click="scrollToTop()">
        <img class="logo" src="@/assets/img/lethimcook.png" alt="Let Him Cook Logo" />
      </router-link>
    </figure>
    <section class="details">
      <ul class="detail-list">
        <li class="item-title"> Contact </li>
        <li class="item-text"> <a href="https://legacy.curseforge.com/account" target="_blank"> <icon class="icon" icon="fa-solid fa-fire-flame-curved" /> CurseForge </a> </li>
        <li class="item-text"> <a href="mailto:jarno@sense.jarnovdmeer.nl" target="_blank"> <icon class="icon" icon="fa-solid fa-envelope" /> E-mail </a> </li>
      </ul>
      <ul class="detail-list">
        <li class="item-title"> SENSE. </li>
        <li class="item-text"> <a href="https://jarnovdmeer.nl/" target="_blank"> <icon class="icon" icon="fa-solid fa-circle-info" /> About Me </a> </li>
        <li class="item-text"> <a href="https://ko-fi.com/isensehostility" target="_blank"> <icon class="icon" icon="fa-solid fa-heart" /> Support Me </a> </li>
        <li class="item-text"> <a href="https://jarnovdmeer.nl/" target="_blank"> <icon class="icon" icon="fa-solid fa-gears" /> Projects </a> </li>
      </ul>
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async scrollToTop() {
      this.delay(0).then(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
      })
    }
  }
}
</script>

<style scoped lang="sass">

ul
  list-style-type: none

a
  all: unset

  &:hover
    cursor: pointer

footer
  display: flex
  justify-content: space-between
  padding-bottom: 30px

.logo
  height: 80px

.details
  display: flex
  gap: 120px

.item-title
  font-size: 24px
  margin-bottom: 10px

.item-text
  font-size: 19px

@media only screen and (max-width: 700px)
  .item-title
    font-size: 22px

  .item-text
    font-size: 17px

@media only screen and (max-width: 500px)
  footer
    flex-direction: column
    align-items: center
    gap: 30px

@media only screen and (max-width: 400px)
  .details
    gap: 70px
</style>
