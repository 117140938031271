import ls from 'localstorage-slim';
let projects = null

export default class ProjectService {

    async setProjects() {
        let cache = ls.get('projects')

        if (cache === null) {
            cache = await this.requestProjects()
        }

        projects = cache
    }

    async requestProjects() {
        const response = await fetch('https://jarno.helioho.st/api/projects')
        let prj = await response.json()
        console.log(prj)

        ls.set('projects', prj, { ttl: 3600000 })
        return prj
    }

    async getProjects() {
        if (projects === null) {
            await this.setProjects()
        }
        return projects
    }

    sortDownloads(projects) {
        projects.sort((a, b) => b.downloadCount - a.downloadCount)
    }

    sortNames(projects) {
        projects.sort((a, b) => a.name.localeCompare(b.name))
    }

    sortNewest(projects) {
        projects.sort((a, b) => a.id - b.id).reverse()
    }

    sortOldest(projects) {
        projects.sort((a, b) => a.id - b.id)
    }

    async getProjectsFiltered(gameId) {
        let projects = await this.getProjects()
        let filteredPrj = []
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].gameId === gameId) {
                filteredPrj.push(projects[i])
            }
        }
        return filteredPrj
    }
}
