<template>
  <MenuBar />

  <slot></slot>

  <div class="footer">
    <hr />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "@/components/Footer";
import MenuBar from "@/components/MenuBar";
export default {
  name: "BaseLayout",
  components: {MenuBar, FooterComponent}
}
</script>

<style scoped lang="sass">
hr
  border: 1px solid white
  width: 80px
  align-self: center

.footer
  display: flex
  flex-direction: column
  margin-top: 60px

  hr
    margin-bottom: 20px
</style>
