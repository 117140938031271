<template>
 <nav class="nav-wrapper">
   <div class="mobile-wrapper">
     <figure>
       <router-link to="/">
         <img class="logo" src="@/assets/img/lethimcook.png" alt="Let Him Cook Logo" />
       </router-link>
     </figure>

     <div class="menu-bars" @click="toggleMenu">
       <div class="bar-1"></div>
       <div class="bar-2"></div>
       <div class="bar-3"></div>
     </div>
   </div>
   <ul class="menu-items" id="menu">
     <li class="text-item"> <router-link to="/minecraft-mods"><span style="display: flex; gap: 5px"><icon class="icon-item" icon="fa-solid fa-hammer"></icon> MINECRAFT</span></router-link> </li>
     <li class="text-item"> <router-link to="/terraria-mods"><span style="display: flex; gap: 5px"><icon class="icon-item" icon="fa-solid fa-seedling"></icon> TERRARIA</span></router-link> </li>
     <li class="text-item"> <router-link to="/projects"><span style="display: flex; gap: 5px"><icon class="icon-item" icon="fa-solid fa-gears"></icon> PROJECTS</span></router-link> </li>
   </ul>
 </nav>
</template>

<script>
export default {
  name: "MenuBar",
  methods: {
    toggleMenu(e) {
      let clicked = e.currentTarget
      let menu = document.getElementById("menu")

      clicked.classList.toggle("menu-active")
      if (clicked.classList.contains("menu-active")) {
        menu.style.opacity = 1
        menu.style.height = "auto"
      } else {
        menu.style.opacity = 0
        menu.style.height = 0
      }
    }
  }
}
</script>

<style scoped lang="sass">

a
  all: unset

  &:hover
    cursor: pointer

.nav-wrapper
  padding-top: 15px
  display: flex

.mobile-wrapper
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

.logo
  height: 80px

ul
  all: unset
  flex-grow: 1
  display: flex
  justify-content: right

  li
    all: unset

.text-item
  font-size: 19px
  margin: 0 10px
  color: $theme-secondary-color

.menu-items
  height: auto
  display: flex
  align-items: center

.menu-bars
  display: none
  flex-direction: column
  align-items: flex-end

.bar-1
  width: 38px
  height: 2px
  background-color: white
  margin: 3.5px 0
  transition: 0.2s

.bar-2
  width: 30px
  height: 2px
  background-color: white
  margin: 3.5px 0
  transition: 0.2s

.bar-3
  width: 22px
  height: 2px
  background-color: white
  margin: 3.5px 0
  transition: 0.2s

.menu-active .bar-1
  width: 38px
  transform: translate(0, 9.4px) rotate(-45deg)

.menu-active .bar-2
  opacity: 0

.menu-active .bar-3
  width: 38px
  transform: translate(0, -9.4px) rotate(45deg)

@media only screen and (max-width: 700px)
  .menu-items
    margin-top: 30px
    opacity: 0
    height: 0
    flex-direction: column
    align-items: flex-end
    margin-bottom: -20px

  .text-item
    height: 60px
    font-size: 17px
    background-color: black
    z-index: 1

  .menu-bars
    display: flex

  .nav-wrapper
    justify-content: space-between
    flex-direction: column

  .logo
    height: 50px
</style>
