<template>
  <div class="wrapper">
    <BaseLayout>
      <section class="top-text">
        <h1>
          SENSE.
        </h1>
        <hr />
        <p> Projects and mods by SENSE. Check the complete project lineup <router-link to="/projects">here <icon style="font-size: 16px" icon="fa-solid fa-arrow-up-right-from-square"></icon></router-link>.</p>
      </section>
      <div class="loading">
        <LoadingCircle v-if="isFetching" />
      </div>
      <ItemShowcase v-if="!isFetching">
        <template #large-shop-item>
          <ShopItem class="large-shop-item" :project="this.projects[0]" />
        </template>
        <template #small-shop-item-1>
          <ShopItem class="small-shop-item" :project="this.projects[1]" />
        </template>
        <template #small-shop-item-2>
          <ShopItem class="small-shop-item" :project="this.projects[2]" />
        </template>
      </ItemShowcase>
    </BaseLayout>
  </div>
</template>

<script>
import ShopItem from "@/components/ShopItem";
import ItemShowcase from "@/components/ItemShowcase";
import BaseLayout from "@/layouts/BaseLayout";
import ProjectService from "@/projects.service";
import LoadingCircle from "@/components/LoadingCircle";

export default {
  name: "HomePage",
  components: {
    BaseLayout,
    ItemShowcase,
    ShopItem,
    LoadingCircle
  },
  data() {
    return {
      projects: [],
      isFetching: true
    }
  },
  async mounted () {
    const service = new ProjectService()
    this.projects = await service.getProjects()
    service.sortDownloads(this.projects)
    this.isFetching = false
  }
}
</script>

<style scoped lang="sass">

.active-sort
  text-decoration: underline

a
  all: unset

  &:hover
    cursor: pointer

p
  max-width: 80vw
  a
    text-decoration: underline

.wrapper
  width: 1100px
  margin: 0 auto
  color: $theme-secondary-color

.large-shop-item
  width: 760px

.small-shop-item
  width: 315px

.top-text
  display: flex
  flex-direction: column
  align-items: center
  margin: 60px 0
  font-size: 24px
  text-align: center

  h1
    font-weight: 400
    margin-bottom: 15px
    font-size: 40px

  hr
    margin-bottom: 5px

hr
  border: 1px solid white
  width: 80px
  align-self: center

.footer
  display: flex
  flex-direction: column
  margin-top: 60px

  hr
    margin-bottom: 20px

.sort
  margin-top: 15px
  font-size: 18px
  display: flex
  justify-content: space-between
  width: 300px

.loading
  display: flex
  justify-content: center
  align-items: center

@media only screen and (max-width: 1200px)
  .wrapper
    width: 800px

  .large-shop-item
    width: 550px

  .small-shop-item
    width: 225px

@media only screen and (max-width: 900px)
  .wrapper
    width: 650px

  .large-shop-item
    width: 460px

  .small-shop-item
    width: 165px

@media only screen and (max-width: 700px)
  .wrapper
    width: 90vw

  .large-shop-item
    width: 520px

  .small-shop-item
    width: 250px

  .top-text
    font-size: 22px

    h1
      font-size: 32px

  hr
    width: 60px

@media only screen and (max-width: 500px)
  .large-shop-item
    width: 390px

  .small-shop-item
    width: 185px

@media only screen and (max-width: 400px)
  .large-shop-item
    width: 340px

  .small-shop-item
    width: 165px

</style>
