<template>
  <div class="shop-items">
    <div class="large-shop-item">
      <slot name="large-shop-item"></slot>
    </div>
    <div class="small-shop-items">
      <slot name="small-shop-item-1"></slot>
      <slot name="small-shop-item-2"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemShowcase"
}
</script>

<style scoped lang="sass">
.shop-items
  display: flex
  gap: 20px

  .small-shop-items
    display: flex
    gap: 15px
    flex-direction: column
    justify-content: space-between

@media only screen and (max-width: 1200px)
  .small-shop-items
    gap: 0

@media only screen and (max-width: 700px)
  .shop-items
    flex-direction: column
    align-items: center
    gap: 0

    .small-shop-items
      flex-direction: row
</style>
