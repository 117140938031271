<template>
  <figure @click="onClicked()" class="shopitem-wrapper">
    <img :src="project.logo.url" :alt="project.name + ' Logo'">
    <figcaption class="caption"> <span class="largetext">{{ project.name }}</span> ─ <span class="bold">{{
        getDownloadShorthand()
      }} <icon class="small-icon" icon="fa-solid fa-arrow-down" /></span> </figcaption>
  </figure>
</template>

<script>
export default {
  name: "ShopItem",
  props: {
    project: Object
  },
  methods: {
    onClicked() {
      window.open(this.project.links.websiteUrl)
    },
    getDownloadShorthand() {
      let shorthand;
      shorthand = this.project.downloadCount > 1000 ? Math.floor(this.project.downloadCount / 1000) + 'K+' : this.project.downloadCount
      return shorthand
    }
  }
}
</script>

<style scoped lang="sass">
  .shopitem-wrapper
    display: flex
    flex-direction: column
    &:hover
      cursor: pointer

  .caption
    color: $theme-secondary-color
    font-size: 19px
    text-align: center
    margin-bottom: 20px
    margin-top: 10px

  .largetext
    font-size: 22px

  .small-icon
    font-size: 15px

  img
    max-width: 90%
    padding: 30px
    border: 3px solid $theme-secondary-color

@media only screen and (max-width: 900px)
  img
    padding: 15px

  .largetext
    font-size: 19px

  .caption
    font-size: 16px

@media only screen and (max-width: 700px)
  img
    padding: 15px
</style>
