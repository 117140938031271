<template>
  <div class="shopitem-wrapper">
    <BaseLayout>
      <section class="top-text">
        <h1>
          Terraria
        </h1>
        <hr />
        <p> A complete lineup of Terraria mods made by SENSE. </p>
        <div class="sort-wrapper">
          <ul class="sorting-options">
            <li @click="changeFilter" class="filter filter-active"> DOWNLOADS </li>
            <li @click="changeFilter" class="filter"> NAME </li>
            <li @click="changeFilter" class="filter"> NEWEST </li>
            <li @click="changeFilter" class="filter"> OLDEST </li>
          </ul>
        </div>
      </section>
      <ul id="mcmods" class="minecraft-mods-list">
        <li v-for="project in projects" :key="project.id">
          <ShopItem class="shop-item" :project="project" />
        </li>
      </ul>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout";
import ShopItem from "@/components/ShopItem";
import ProjectService from "@/projects.service";

export default {
  name: "TerrariaPage",
  components: {
    BaseLayout,
    ShopItem
  },
  methods: {
    changeFilter(e) {
      let filters = document.getElementsByClassName('filter')

      for (let i = 0; i < filters.length; i++) {
        filters.item(i).classList.remove('filter-active')
      }

      let clicked = e.currentTarget

      clicked.classList.add('filter-active')

      switch (clicked.innerText) {
        case 'DOWNLOADS': new ProjectService().sortDownloads(this.projects); break;
        case 'NAME': new ProjectService().sortNames(this.projects); break;
        case 'NEWEST': new ProjectService().sortNewest(this.projects); break;
        case 'OLDEST': new ProjectService().sortOldest(this.projects); break;
      }
    }
  },
  data() {
    return {
      projects: []
    }
  },
  async mounted () {
    let service = new ProjectService()
    this.projects = await service.getProjects()
    this.projects = await service.getProjectsFiltered(431)
    service.sortDownloads(this.projects)
  }
}
</script>

<style scoped lang="sass">
.shopitem-wrapper
  width: 1100px
  margin: 0 auto
  color: $theme-secondary-color

hr
  border: 1px solid white
  width: 80px
  align-self: center

.footer
  display: flex
  flex-direction: column
  margin-top: 60px

  hr
    margin-bottom: 20px

.shop-item
  width: 290px

li
  all: unset

.minecraft-mods-list
  margin-top: 30px
  display: grid
  grid-template-columns: 1fr 1fr 1fr

.sort-wrapper
  display: flex
  width: 100%
  justify-content: center

.sorting-options
  display: flex
  width: 32%
  justify-content: space-between
  margin-top: 15px

.filter
  font-size: 18px

  &:hover
    cursor: pointer

  &.filter-active
    text-decoration: underline

hr
  border: 1px solid white
  width: 80px

.top-text
  display: flex
  flex-direction: column
  align-items: center
  margin: 60px 0
  font-size: 25px
  text-align: center

  h1
    font-weight: 400
    margin-bottom: 15px

  hr
    margin-bottom: 5px


@media only screen and (max-width: 1200px)
  .shopitem-wrapper
    width: 800px

  .shop-item
    width: 230px

  .sorting-options
    width: 42%

@media only screen and (max-width: 900px)
  .shopitem-wrapper
    width: 650px

  .shop-item
    width: 180px

  .sorting-options
    width: 50%

  .filter
    font-size: 16px

@media only screen and (max-width: 700px)
  .shopitem-wrapper
    width: 90vw

  .minecraft-mods-list
    grid-template-columns: 1fr 1fr

  .shop-item
    width: 260px

  .top-text
    font-size: 22px

    h1
      font-size: 32px

  hr
    width: 60px

  .sorting-options
    width: 55%

@media only screen and (max-width: 600px)
  .shop-item
    width: 200px

  .top-text
    font-size: 22px

    h1
      font-size: 32px

  hr
    width: 60px

  .sorting-options
    width: 70%

@media only screen and (max-width: 480px)
  .shop-item
    width: 170px

  .sorting-options
    width: 80%

  .sort-wrapper
    margin-top: 15px

  .sorting-options
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 15px

@media only screen and (max-width: 400px)
  .shop-item
    width: 150px

  .sorting-options
    width: 80%

@media only screen and (max-width: 360px)
  .shop-item
    width: 250px

  .minecraft-mods-list
    grid-template-columns: 1fr
</style>
